import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { LoggedInService } from './services/loggedIn.service';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
    canActivate: [LoggedInService],
  },
  {
    path: 'threads',
    loadChildren: () =>
      import('./threads/threads.module').then((m) => m.ThreadsPageModule),
    canActivate: [LoggedInService],
  },
  {
    path: 'messages',
    loadChildren: () =>
      import('./messages/messages.module').then((m) => m.MessagesPageModule),
    canActivate: [LoggedInService],
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('./projects/projects.module').then((m) => m.ProjectsPageModule),
    canActivate: [LoggedInService],
  },
  {
    path: 'projects/filters',
    loadChildren: () =>
      import('./project-filters/project-filters.module').then(
        (m) => m.ProjectFiltersPageModule
      ),
    canActivate: [LoggedInService],
  },
  {
    path: 'projects/:seoUrlCat/:seoUrl/details',
    loadChildren: () =>
      import('./project-details/project-details.module').then(
        (m) => m.ProjectDetailsPageModule
      ),
    canActivate: [LoggedInService],
  },
  {
    path: 'projects/:seoUrlCat/:seoUrl/proposals',
    loadChildren: () =>
      import('./project-bids/project-bids.module').then(
        (m) => m.ProjectBidsPageModule
      ),
    canActivate: [LoggedInService],
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'projects-mine',
    loadChildren: () => import('./projects-mine/projects-mine.module').then( m => m.ProjectsMinePageModule)
  },  {
    path: 'filters',
    loadChildren: () => import('./filters/filters.module').then( m => m.FiltersPageModule)
  },
  {
    path: 'device-filter',
    loadChildren: () => import('./device-filter/device-filter.module').then( m => m.DeviceFilterPageModule)
  },
  {
    path: 'job-tags',
    loadChildren: () => import('./job-tags/job-tags.module').then( m => m.JobTagsPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
