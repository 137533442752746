import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FcmService } from './services/fcm.service';
import { StorageService } from './services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private fcmService: FcmService,
    private readonly storageService: StorageService
  ) {}

  async ngOnInit() {
    const theme = await this.storageService.get('userPref.theme');
    if (theme === 'dark') {
      document.getElementsByTagName('body')[0].classList.add('dark');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('dark');
    }
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      await this.fcmService.initPush();
    });
  }
}
