import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedInService implements CanActivate {
  constructor(private router: Router, private storageService: StorageService) {}
  async canActivate() {
    const fpApiToken = await this.storageService.get('fpApiToken');
    if (fpApiToken) {
      return true;
    }
    this.router.navigate(['/login'], { queryParams: { isLoggedOut: 1 } });
    return false;
  }
}
