import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private readonly storage: Storage) {}

  async set(key: string, value: string) {
    return await this.storage.set(key, value);
  }
  async get(key: string) {
    return await this.storage.get(key);
  }
  async remove(key: string) {
    return await this.storage.remove(key);
  }
  async clear() {
    await this.storage.clear();
  }
}
