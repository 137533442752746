import { Injectable } from '@angular/core';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  Capacitor,
} from '@capacitor/core';
import { StorageService } from './storage.service';

const { PushNotifications } = Plugins;

@Injectable({
  providedIn: 'root',
})
export class FcmService {
  constructor(public storageService: StorageService) {}

  async initPush() {
    if (Capacitor.platform !== 'web') {
      await this.registerPush();
    }
  }

  async registerPush() {
    const permission = await PushNotifications.requestPermission();
    if (permission) {
      await PushNotifications.register();
    }

    PushNotifications.addListener(
      'registration',
      async (token: PushNotificationToken) => {
        await this.storageService.set('pushToken', token.value);
      }
    );

    PushNotifications.addListener('registrationError', (error: any) => {});

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotification) => {}
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: PushNotificationActionPerformed) => {
        PushNotifications.removeAllDeliveredNotifications();
      }
    );
  }
}
